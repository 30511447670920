import { useEffect, useState } from 'react'

export default function useLocalStorage(key, initialValue) {
  const [value, setValue] = useState(() => {
    let jsonValue = null

    if (typeof window !== 'undefined') {
      jsonValue = localStorage.getItem(key)
    }

    if (jsonValue && jsonValue !== 'undefined') {
      return JSON.parse(jsonValue)
    }

    return initialValue
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }, [key, value])

  return [value, setValue]
}
