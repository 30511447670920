export const lightTheme = {
  colors: {
    background: '#ffffff',
    text: '#323232',
    primary: '#8257e6',
    error: '#F00',
    tag: '#808080'
  }
}

export const darkTheme = {
  colors: {
    background: '#191919',
    text: '#fff',
    primary: '#8257e6',
    error: '#F00',
    tag: '#c0c0c0'
  }
}

export default lightTheme
