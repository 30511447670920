import React, { useState, useEffect } from 'react'
import { useTheme, Theme } from '../../providers/theme'
import SocialIcon from './social-icon'
import { Container, DonationButton } from './style'
import Image from 'next/image'

const Footer: React.FC = () => {
  const { theme } = useTheme()
  const [githubImageURI, setGithubImageURI] = useState('/github-icon-light.svg')
  const [linkedinImageURI, setLinkedinImageURI] = useState(
    '/linkedin-icon-light.svg'
  )
  const [twitterImageURI, setTwitterImageURI] = useState(
    '/twitter-icon-light.svg'
  )

  useEffect(() => {
    const newGithubImageURI =
      theme === Theme.DARK ? '/github-icon-light.svg' : '/github-icon.svg'
    const newLinkedinImageURI =
      theme === Theme.DARK ? '/linkedin-icon-light.svg' : '/linkedin-icon.svg'
    const newTwitterImageURI =
      theme === Theme.DARK ? '/twitter-icon-light.svg' : '/twitter-icon.svg'

    setGithubImageURI(newGithubImageURI)
    setLinkedinImageURI(newLinkedinImageURI)
    setTwitterImageURI(newTwitterImageURI)
  }, [theme])

  return (
    <>
      <Container>
        <SocialIcon
          src={githubImageURI}
          alt="github icon"
          url="https://github.com/ricardo93borges"
        />
        <SocialIcon
          src={linkedinImageURI}
          alt="linkedin icon"
          url="https://www.linkedin.com/in/ricardo-borges-silva/"
        />
        <SocialIcon
          src={twitterImageURI}
          alt="twitter icon"
          url="https://twitter.com/Borges_rocks"
        />
      </Container>
      <DonationButton>
        <a
          href="https://www.buymeacoffee.com/ricardoborges"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me A Coffee"
            width={217}
            height={60}
          />
        </a>
      </DonationButton>
    </>
  )
}

export default Footer
