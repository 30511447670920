import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import Switch from 'react-switch'
import {
  Container,
  HeaderImage,
  HeaderDescription,
  HeaderSwitchWrapper
} from './style'
import { Theme, useTheme, LOCAL_STORAGE_THEME_KEY } from '../../providers/theme'
import useLocalStorage from '../../hooks/useLocalStorage'

const Header: React.FC = () => {
  const { theme, setTheme } = useTheme()
  const [_, setSelectedTheme] = useLocalStorage(
    LOCAL_STORAGE_THEME_KEY,
    Theme.LIGHT
  )

  const handleThemeSwitch = () => {
    const selectedTheme = theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT
    setTheme(selectedTheme)
    setSelectedTheme(selectedTheme)
  }

  return (
    <Container>
      <Link href="/">
        <HeaderImage>
          <Image
            className="profileImage"
            src="/me.jpg"
            alt="Ricardo Borges"
            width={75}
            height={75}
          />
        </HeaderImage>
      </Link>
      <HeaderDescription>
        <h1>
          <Link href="/">Ricardo Borges</Link>
        </h1>
        <small>
          <i>Personal blog</i>
        </small>
      </HeaderDescription>
      <HeaderSwitchWrapper>
        <Switch
          onChange={() => handleThemeSwitch()}
          checked={theme === Theme.DARK}
          onColor={'#000'}
          offColor={'#000'}
          checkedIcon={<img src={'/moon-icon.svg'} />}
          uncheckedIcon={<img src={'/sun-icon.svg'} />}
        />
      </HeaderSwitchWrapper>
    </Container>
  )
}

export default Header
