import React, {
  ReactElement,
  useEffect,
  useState,
  createContext,
  useContext
} from 'react'
import useLocalStorage from '../hooks/useLocalStorage'

export const LOCAL_STORAGE_THEME_KEY = 'theme'

export enum Theme {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

type ThemeProviderProps = {
  children?: ReactElement
}

type UseTheme = {
  theme: Theme
  setTheme: (theme: Theme) => void
}

export const ThemeContext = createContext<Partial<UseTheme>>({
  theme: Theme.LIGHT,
  setTheme: theme => console.warn('no theme provider')
})

export const ThemeProvider: React.FC<ThemeProviderProps> = (
  props: ThemeProviderProps
) => {
  const [theme, setTheme] = useState(Theme.LIGHT)
  const [selectedTheme] = useLocalStorage(LOCAL_STORAGE_THEME_KEY, Theme.LIGHT)

  useEffect(() => {
    setTheme(selectedTheme)
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
