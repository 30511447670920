import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    transition: background-color 0.8s ease;    
    background-color: ${props => props.theme.colors.background};    
    font: 400 1rem Roboto, sans-serif;

    :not(code), a {
      transition: color 0.8s ease;
      color: ${props => props.theme.colors.text};
    }
  }

  header * {
    transition: color 0.8s ease;
    color: ${props => props.theme.colors.text};
  }
`
