import styled from 'styled-components'

export const Container = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 30px;

  & img {
    margin: 0 5px;
  }
`

export const DonationButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;

  & img {
    margin: 0 5px;
  }
`
