import React, { ReactElement, useEffect } from 'react'
import { useRouter } from 'next/router'
import firebase from '../config/firebase'

type AuthProviderProps = {
  children?: ReactElement
}

const AuthProvider: React.FC<AuthProviderProps> = (
  props: AuthProviderProps
) => {
  const router = useRouter()

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      const pathname = router.pathname
      if (!user && pathname.includes('/admin') && pathname !== '/admin/login') {
        router.push('/admin/login')
      }
    })
  }, [])

  return props.children
}

export default AuthProvider
