import React from 'react'
import Image from 'next/image'

type SocialIconProps = {
  url: string
  src: string
  alt: string
}

const SocialIcon: React.FC<SocialIconProps> = ({ url, src, alt }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Image src={src} width={32} height={32} alt={alt} />
    </a>
  )
}

export default SocialIcon
