import React, { ReactElement, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme } from '../styles/theme'
import { useTheme, LOCAL_STORAGE_THEME_KEY, Theme } from './theme'
import useLocalStorage from '../hooks/useLocalStorage'

type StyleComponentThemeProviderProps = {
  children?: ReactElement
}

export const StyleComponentThemeProvider: React.FC<
  StyleComponentThemeProviderProps
> = (props: StyleComponentThemeProviderProps) => {
  const { theme } = useTheme()
  const [selectedTheme] = useLocalStorage(LOCAL_STORAGE_THEME_KEY, Theme.LIGHT)
  const [styleComponentTheme, setStyleComponentTheme] = useState(lightTheme)

  useEffect(() => {
    const newTheme = selectedTheme === Theme.LIGHT ? lightTheme : darkTheme
    setStyleComponentTheme(newTheme)
  }, [])

  useEffect(() => {
    const newTheme = theme === Theme.LIGHT ? lightTheme : darkTheme
    setStyleComponentTheme(newTheme)
  }, [theme])

  return (
    <ThemeProvider theme={styleComponentTheme}>{props.children}</ThemeProvider>
  )
}
